@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div.suspense-fallback-loader {
  position: fixed;
  top: 50%;
  right: 50%;
  z-index: 10000000;
}

.design-card.card-stats .icon-big {
  font-size: 3em;
  min-height: 64px; }
  .design-card.card-stats .icon-big i {
    font-weight: bold;
    line-height: 59px; }

.design-card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
}

/* On mouse-over, add a deeper shadow */
.design-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

/* react-widgets MultiSelect */

.rw-list-empty, .rw-list-option, .rw-list-optgroup {
    padding: 0 .75em;
    outline: 0;
    margin-top: 0;
}

.rw-multiselect-tag {
    vertical-align: top;
    margin-top: 0 !important;
    margin-bottom: 4px;
}


/* khan/react-multi-select custom styles */

div.multi-select div.dropdown-heading {
  width: 100% !important;
  height: 29px !important;
  border: 1px #f5d4b9 solid !important;
}

div.multi-select span.dropdown-heading-dropdown-arrow span {
  border-color: rgb(153, 153, 153) transparent transparent !important;
}

div.multi-select label.select-item {
  /*width: 200px;*/
}

div.multi-select div.dropdown-content {
  width: 150% !important;
}

/* kendo react multiselect styles */

.k-widget .k-multiselect .k-header, .k-multiselect-wrap .k-floatwrap, .k-searchbar {
  border: 1px #f5d4b9 solid !important;
}

.k-multiselect .k-multiselect-wrap .k-searchbar, .k-dropdowntree .k-multiselect-wrap .k-searchbar {
  width: 100% !important;
}

.k-multiselect .k-multiselect-wrap, .k-dropdowntree .k-multiselect-wrap {
  padding: 0 !important;
}

.k-animation-container.k-animation-container-relative.k-list-container.k-reset.k-animation-container-shown {
  /*width: 25% !important;*/
  z-index: 999999 !important;
}


/* designer styles for switch (checkbox) */

.slider:before {
  bottom: 3px !important;
}

/* react kendo multiselect styles */

.k-animation-container {
  width: 300px !important;
}

.k-multiselect-wrap.k-floatwrap .k-button {
  width: 97% !important;
}

.k-animation-container .heading-filter-row {
  /*margin-left: 0.1rem !important;*/
}

.k-list-scroller .k-item {
  height: 60px !important;
}


#tableData {
  max-height: 90vh;
  overflow-y: auto;
}


/* place text at bottom right */

.main_div {
  position: relative;
  height: 200px;
}
.sub_div {
  position: absolute;
  bottom: 0px;
  right: 0px;
}


/* autocomplete dropdown input */

.autocomplete-dropdown-input {
  background-color: white;
  padding: 0px 0px;
  margin-right: 5px;
  border: 1px #f5d4b9 solid;
  border-radius: 5px;
  min-width: 150px;
  max-height: 33px;
}

.class-header-input {
  border: none;
}


/* react kendo search option focus */

.k-list .k-item:focus, .k-list .k-item.k-state-focused, .k-list-optionlabel:focus, .k-list-optionlabel.k-state-focused {
    box-shadow: inset 0 0 0 2px rgba(0,0,0,.13);
    background-color: #cccc;
}


.FiltersTable th,td{
  border: 2px #f5d4b9 solid;
}

.FiltersTable th{
  color: #212529;
  padding: 10px 0px;
  font-size: 11px;
  text-align: center;
}

.FiltersTable span{
  /* border: 2px #f5d4b9 solid; */
  padding: 1px 6px;
  margin: 2px 2px;
  display: inline-block;
  word-break: break-word;
  font-size: 13px;

}

.FiltersTable th,td{
  word-break: initial;
}


.FiltersTable td{
  max-width: 115px;
  /* min-width: 110px */
}

.FiltersTable tbody>tr>:nth-child(8),tbody>tr>:nth-child(9){
  max-width: 69px;
}

.FiltersTable tbody>tr>:nth-child(4){
  width: 20px;
}

.FiltersTable tbody>tr>:nth-child(1){
  min-width: 150px;
}

.analytics-justified-filters > .col-3{max-width: 24%!important;}


/* analytics filter justified spacing */
@media (min-width: 768px) {
  .analytics-justified-filters .col-md-2 {
      flex: 0 0 19.999999% !important;
      max-width: 20.666667% !important;
  }

  .analytics-extended-filters .col-md-2 {
    flex: 0 0 17.999999% !important;
    max-width: 20.666667% !important;
  }
}

.coverSpinner {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.coverSpinner::after {
  content: "";
  display: block;
  position: absolute;
  left: 48%;
  top: 40%;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-color: black;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
}

body {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: 400;
  background-color: #f8f8f8;
}
a,
button {
  transition: all 0.5s;
}
* {
  scrollbar-width: thin;
  scrollbar-color: #999 #ccc;
}
*::-webkit-scrollbar {
  width: 12px;
}
*::-webkit-scrollbar-track {
  background: #ccc;
}
*::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 20px;
  border: 3px solid #ccc;
}

.bg-gradient {
  background: rgb(35, 4, 43);
  background: linear-gradient(
    to right,
    rgb(35 4 43 / 5%) 26%,
    rgb(229 99 13) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#23042b', endColorstr='#e5630d',GradientType=1 );
}
.dropdown-menu {
  font-size: 13px;
}
.navbar-brand {
  max-width: 200px;
  padding: 0px;
}
.navbar-dark .navbar-nav .nav-link {
  color: black;
  font-weight: 600;
}
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: #ed8732;
}
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show > .nav-link {
  color: #ed8732;
}
.card-header {
  background-color: #f9efe7;
  border-color: #f5d4b9;
}
.card-header h1 {
  font-size: 18px;
  color: #ed8732;
  margin: 0px;
}
.nav-pro-pic {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-left: 5px;
  margin-right: 5px;
}
.btn-primary {
  background-color: #ed8732;
  border-color: #ed8732;
  font-size: 13px;
}
.btn-secondary {
  font-size: 13px;
}
.btn-outline-primary {
  border-color: #ed8732;
  color: #23042b;
}
.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle,
.btn-primary.focus,
.btn-primary:focus,
.btn-primary:hover {
  background-color: #e2620d;
  border-color: #e2620d;
}
[class*="tooltip--"] {
  position: relative;
  display: inline-block;
}
[class*="tooltip--"]:after,
[class*="tooltip--"]:before {
  position: absolute;
  transform: translate3d(0, 0, 0);
  visibility: hidden;
  opacity: 0;
  z-index: 1000000;
  pointer-events: none;
  transition: 0.3s ease;
  transition-delay: 0s;
}
[class*="tooltip--"]:hover:after,
[class*="tooltip--"]:hover:before {
  visibility: visible;
  opacity: 1;
  transition-delay: 0.1s;
}
[class*="tooltip--"]:before {
  content: "";
  position: absolute;
  background: 0 0;
  border: 6px solid transparent;
  z-index: 1000001;
}
[class*="tooltip--"]:after {
  background: #23042b;
  color: #fff;
  padding: 8px 10px;
  font-size: 12px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 12px;
  white-space: nowrap;
  text-shadow: 0 -1px 0 #000;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
}
[class*="tooltip--"][aria-label]:after {
  content: attr(aria-label);
}
[class*="tooltip--"][data-hint]:after {
  content: attr(data-hint);
}
[aria-label=""]:after,
[aria-label=""]:before,
[data-hint=""]:after,
[data-hint=""]:before {
  display: none !important;
}
.tooltip--top-left:before,
.tooltip--top-right:before,
.tooltip--top:before {
  border-top-color: #23042b;
}
.tooltip--bottom-left:before,
.tooltip--bottom-right:before,
.tooltip--bottom:before {
  border-bottom-color: #23042b;
}
.tooltip--top:after,
.tooltip--top:before {
  bottom: 100%;
  left: 50%;
}
.tooltip--top:before {
  margin-bottom: -11px;
  left: calc(50% - 6px);
}
.tooltip--top:after {
  transform: translateX(-50%);
}
.tooltip--top:hover:before {
  transform: translateY(-8px);
}
.tooltip--top:hover:after {
  transform: translateX(-50%) translateY(-8px);
}
.tooltip--bottom:after,
.tooltip--bottom:before {
  top: 100%;
  left: 50%;
}
.tooltip--bottom:before {
  margin-top: -11px;
  left: calc(50% - 6px);
}
.tooltip--bottom:after {
  transform: translateX(-50%);
}
.tooltip--bottom:hover:before {
  transform: translateY(8px);
}
.tooltip--bottom:hover:after {
  transform: translateX(-50%) translateY(8px);
}
.tooltip--right:before {
  border-right-color: #23042b;
  margin-left: -11px;
  margin-bottom: -6px;
}
.tooltip--right:after {
  margin-bottom: -14px;
}
.tooltip--right:after,
.tooltip--right:before {
  left: 100%;
  bottom: 50%;
}
.tooltip--right:hover:after,
.tooltip--right:hover:before {
  transform: translateX(8px);
}
.tooltip--left:before {
  border-left-color: #23042b;
  margin-right: -11px;
  margin-bottom: -6px;
}
.tooltip--left:after {
  margin-bottom: -14px;
}
.tooltip--left:after,
.tooltip--left:before {
  right: 100%;
  bottom: 50%;
}
.tooltip--left:hover:after,
.tooltip--left:hover:before {
  transform: translateX(-8px);
}
.tooltip--top-left:after,
.tooltip--top-left:before {
  bottom: 100%;
  left: 50%;
}
.tooltip--top-left:before {
  margin-bottom: -11px;
  left: calc(50% - 6px);
}
.tooltip--top-left:after {
  transform: translateX(-100%);
  margin-left: 12px;
}
.tooltip--top-left:hover:before {
  transform: translateY(-8px);
}
.tooltip--top-left:hover:after {
  transform: translateX(-100%) translateY(-8px);
}
.tooltip--top-right:after,
.tooltip--top-right:before {
  bottom: 100%;
  left: 50%;
}
.tooltip--top-right:before {
  margin-bottom: -11px;
  left: calc(50% - 6px);
}
.tooltip--top-right:after {
  transform: translateX(0);
  margin-left: -12px;
}
.tooltip--top-right:hover:after,
.tooltip--top-right:hover:before {
  transform: translateY(-8px);
}
.tooltip--bottom-left:after,
.tooltip--bottom-left:before {
  top: 100%;
  left: 50%;
}
.tooltip--bottom-left:before {
  margin-top: -11px;
  left: calc(50% - 6px);
}
.tooltip--bottom-left:after {
  transform: translateX(-100%);
  margin-left: 12px;
}
.tooltip--bottom-left:hover:before {
  transform: translateY(8px);
}
.tooltip--bottom-left:hover:after {
  transform: translateX(-100%) translateY(8px);
}
.tooltip--bottom-right:after,
.tooltip--bottom-right:before {
  top: 100%;
  left: 50%;
}
.tooltip--bottom-right:before {
  margin-top: -11px;
  left: calc(50% - 6px);
}
.tooltip--bottom-right:after {
  transform: translateX(0);
  margin-left: -12px;
}
.tooltip--bottom-right:hover:after,
.tooltip--bottom-right:hover:before {
  transform: translateY(8px);
}
.tooltip--large:after,
.tooltip--medium:after,
.tooltip--small:after {
  white-space: normal;
  line-height: 1.4em;
  word-wrap: break-word;
}
.tooltip--small:after {
  width: 80px;
}
.tooltip--medium:after {
  width: 150px;
}
.tooltip--large:after {
  width: 300px;
}
.tooltip--error:after {
  background-color: #b34e4d;
  text-shadow: 0 -1px 0 #592726;
}
.tooltip--error.tooltip--top-left:before,
.tooltip--error.tooltip--top-right:before,
.tooltip--error.tooltip--top:before {
  border-top-color: #b34e4d;
}
.tooltip--error.tooltip--bottom-left:before,
.tooltip--error.tooltip--bottom-right:before,
.tooltip--error.tooltip--bottom:before {
  border-bottom-color: #b34e4d;
}
.tooltip--error.tooltip--left:before {
  border-left-color: #b34e4d;
}
.tooltip--error.tooltip--right:before {
  border-right-color: #b34e4d;
}
.tooltip--warning:after {
  background-color: #c09854;
  text-shadow: 0 -1px 0 #6c5328;
}
.tooltip--warning.tooltip--top-left:before,
.tooltip--warning.tooltip--top-right:before,
.tooltip--warning.tooltip--top:before {
  border-top-color: #c09854;
}
.tooltip--warning.tooltip--bottom-left:before,
.tooltip--warning.tooltip--bottom-right:before,
.tooltip--warning.tooltip--bottom:before {
  border-bottom-color: #c09854;
}
.tooltip--warning.tooltip--left:before {
  border-left-color: #c09854;
}
.tooltip--warning.tooltip--right:before {
  border-right-color: #c09854;
}
.tooltip--info:after {
  background-color: #3986ac;
  text-shadow: 0 -1px 0 #1a3c4d;
}
.tooltip--info.tooltip--top-left:before,
.tooltip--info.tooltip--top-right:before,
.tooltip--info.tooltip--top:before {
  border-top-color: #3986ac;
}
.tooltip--info.tooltip--bottom-left:before,
.tooltip--info.tooltip--bottom-right:before,
.tooltip--info.tooltip--bottom:before {
  border-bottom-color: #3986ac;
}
.tooltip--info.tooltip--left:before {
  border-left-color: #3986ac;
}
.tooltip--info.tooltip--right:before {
  border-right-color: #3986ac;
}
.tooltip--success:after {
  background-color: #458746;
  text-shadow: 0 -1px 0 #1a321a;
}
.tooltip--success.tooltip--top-left:before,
.tooltip--success.tooltip--top-right:before,
.tooltip--success.tooltip--top:before {
  border-top-color: #458746;
}
.tooltip--success.tooltip--bottom-left:before,
.tooltip--success.tooltip--bottom-right:before,
.tooltip--success.tooltip--bottom:before {
  border-bottom-color: #458746;
}
.tooltip--success.tooltip--left:before {
  border-left-color: #458746;
}
.tooltip--success.tooltip--right:before {
  border-right-color: #458746;
}
.tooltip--always:after,
.tooltip--always:before {
  opacity: 1;
  visibility: visible;
}
.tooltip--always.tooltip--top:before {
  transform: translateY(-8px);
}
.tooltip--always.tooltip--top:after {
  transform: translateX(-50%) translateY(-8px);
}
.tooltip--always.tooltip--top-left:before {
  transform: translateY(-8px);
}
.tooltip--always.tooltip--top-left:after {
  transform: translateX(-100%) translateY(-8px);
}
.tooltip--always.tooltip--top-right:after,
.tooltip--always.tooltip--top-right:before {
  transform: translateY(-8px);
}
.tooltip--always.tooltip--bottom:before {
  transform: translateY(8px);
}
.tooltip--always.tooltip--bottom:after {
  transform: translateX(-50%) translateY(8px);
}
.tooltip--always.tooltip--bottom-left:before {
  transform: translateY(8px);
}
.tooltip--always.tooltip--bottom-left:after {
  transform: translateX(-100%) translateY(8px);
}
.tooltip--always.tooltip--bottom-right:after,
.tooltip--always.tooltip--bottom-right:before {
  transform: translateY(8px);
}
.tooltip--always.tooltip--left:after,
.tooltip--always.tooltip--left:before {
  transform: translateX(-8px);
}
.tooltip--always.tooltip--right:after,
.tooltip--always.tooltip--right:before {
  transform: translateX(8px);
}
.tooltip--rounded:after {
  border-radius: 4px;
}
.tooltip--no-animate:after,
.tooltip--no-animate:before {
  transition-duration: 0s;
}
.tooltip--bounce:after,
.tooltip--bounce:before {
  transition: opacity 0.3s ease, visibility 0.3s ease,
    transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
}
.tooltip--no-shadow:after,
.tooltip--no-shadow:before {
  text-shadow: none;
  text-shadow: initial;
  box-shadow: none;
  box-shadow: initial;
}
.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #23042b;
}
.daterangepicker {
  position: absolute;
  color: inherit;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ddd;
  width: 278px;
  max-width: none;
  padding: 0;
  margin-top: 7px;
  top: 100px;
  left: 20px;
  z-index: 3001;
  display: none;
  font-family: arial;
  font-size: 15px;
  line-height: 1em;
}
.daterangepicker:after,
.daterangepicker:before {
  position: absolute;
  display: inline-block;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: "";
}
.daterangepicker:before {
  top: -7px;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #ccc;
}
.daterangepicker:after {
  top: -6px;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
}
.daterangepicker.opensleft:before {
  right: 9px;
}
.daterangepicker.opensleft:after {
  right: 10px;
}
.daterangepicker.openscenter:before {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
}
.daterangepicker.openscenter:after {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
}
.daterangepicker.opensright:before {
  left: 9px;
}
.daterangepicker.opensright:after {
  left: 10px;
}
.daterangepicker.drop-up {
  margin-top: -7px;
}
.daterangepicker.drop-up:before {
  top: auto;
  top: initial;
  bottom: -7px;
  border-bottom: 0;
  border-bottom: initial;
  border-top: 7px solid #ccc;
}
.daterangepicker.drop-up:after {
  top: auto;
  top: initial;
  bottom: -6px;
  border-bottom: 0;
  border-bottom: initial;
  border-top: 6px solid #fff;
}
.daterangepicker.single .daterangepicker .ranges,
.daterangepicker.single .drp-calendar {
  float: none;
}
.daterangepicker.single .drp-selected {
  display: none;
}
.daterangepicker.show-calendar .drp-calendar {
  display: block;
}
.daterangepicker.show-calendar .drp-buttons {
  display: block;
}
.daterangepicker.auto-apply .drp-buttons {
  display: none;
}
.daterangepicker .drp-calendar {
  display: none;
  max-width: 270px;
}
.daterangepicker .drp-calendar.left {
  padding: 8px 0 8px 8px;
}
.daterangepicker .drp-calendar.right {
  padding: 8px;
}
.daterangepicker .drp-calendar.single .calendar-table {
  border: none;
}
.daterangepicker .calendar-table .next span,
.daterangepicker .calendar-table .prev span {
  color: #fff;
  border: solid #000;
  border-width: 0 2px 2px 0;
  border-radius: 0;
  display: inline-block;
  padding: 3px;
}
.daterangepicker .calendar-table .next span {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.daterangepicker .calendar-table .prev span {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}
.daterangepicker .calendar-table td,
.daterangepicker .calendar-table th {
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  min-width: 32px;
  width: 32px;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid transparent;
  white-space: nowrap;
  cursor: pointer;
}
.daterangepicker .calendar-table {
  border: 1px solid #fff;
  border-radius: 4px;
  background-color: #fff;
}
.daterangepicker .calendar-table table {
  width: 100%;
  margin: 0;
  border-spacing: 0;
  border-collapse: collapse;
}
.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
  background-color: #eee;
  border-color: transparent;
  color: inherit;
}
.daterangepicker td.week,
.daterangepicker th.week {
  font-size: 80%;
  color: #ccc;
}
.daterangepicker td.off,
.daterangepicker td.off.end-date,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date {
  background-color: #fff;
  border-color: transparent;
  color: #999;
}
.daterangepicker td.in-range {
  background-color: #f5cffe;
  border-color: transparent;
  color: #000;
  border-radius: 0;
}
.daterangepicker td.start-date {
  border-radius: 4px 0 0 4px;
}
.daterangepicker td.end-date {
  border-radius: 0 4px 4px 0;
}
.daterangepicker td.start-date.end-date {
  border-radius: 4px;
}
.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #23042b;
  border-color: transparent;
  color: #fff;
}
.daterangepicker th.month {
  width: auto;
}
.daterangepicker option.disabled,
.daterangepicker td.disabled {
  color: #999;
  cursor: not-allowed;
  text-decoration: line-through;
}
.daterangepicker select.monthselect,
.daterangepicker select.yearselect {
  font-size: 12px;
  padding: 1px;
  height: auto;
  margin: 0;
  cursor: default;
}
.daterangepicker select.monthselect {
  margin-right: 2%;
  width: 56%;
}
.daterangepicker select.yearselect {
  width: 40%;
}
.daterangepicker select.ampmselect,
.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect {
  width: 50px;
  margin: 0 auto;
  background: #eee;
  border: 1px solid #eee;
  padding: 2px;
  outline: 0;
  font-size: 12px;
}
.daterangepicker .calendar-time {
  text-align: center;
  margin: 4px auto 0 auto;
  line-height: 30px;
  position: relative;
}
.daterangepicker .calendar-time select.disabled {
  color: #ccc;
  cursor: not-allowed;
}
.daterangepicker .drp-buttons {
  clear: both;
  text-align: right;
  padding: 8px;
  border-top: 1px solid #ddd;
  display: none;
  line-height: 12px;
  vertical-align: middle;
}
.daterangepicker .drp-selected {
  display: inline-block;
  font-size: 12px;
  padding-right: 8px;
}
.daterangepicker .drp-buttons .btn {
  margin-left: 8px;
  font-size: 12px;
  font-weight: 700;
  padding: 4px 8px;
}
.daterangepicker.show-ranges.single.rtl .drp-calendar.left {
  border-right: 1px solid #ddd;
}
.daterangepicker.show-ranges.single.ltr .drp-calendar.left {
  border-left: 1px solid #ddd;
}
.daterangepicker.show-ranges.rtl .drp-calendar.right {
  border-right: 1px solid #ddd;
}
.daterangepicker.show-ranges.ltr .drp-calendar.left {
  border-left: 1px solid #ddd;
}
.daterangepicker .ranges {
  float: none;
  text-align: left;
  margin: 0;
}
.daterangepicker.show-calendar .ranges {
  margin-top: 8px;
}
.daterangepicker .ranges ul {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  width: 100%;
}
.daterangepicker .ranges li {
  font-size: 12px;
  padding: 8px 12px;
  cursor: pointer;
}
.daterangepicker .ranges li:hover {
  background-color: #eee;
}
.daterangepicker .ranges li.active {
  background-color: #23042b;
  color: #fff;
}
@media (min-width: 564px) {
  .daterangepicker {
    width: auto;
  }
  .daterangepicker .ranges ul {
    width: 140px;
  }
  .daterangepicker.single .ranges ul {
    width: 100%;
  }
  .daterangepicker.single .drp-calendar.left {
    clear: none;
  }
  .daterangepicker.single .drp-calendar,
  .daterangepicker.single .ranges {
    float: left;
  }
  .daterangepicker {
    direction: ltr;
    text-align: left;
  }
  .daterangepicker .drp-calendar.left {
    clear: left;
    margin-right: 0;
  }
  .daterangepicker .drp-calendar.left .calendar-table {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .daterangepicker .drp-calendar.right {
    margin-left: 0;
  }
  .daterangepicker .drp-calendar.right .calendar-table {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .daterangepicker .drp-calendar.left .calendar-table {
    padding-right: 8px;
  }
  .daterangepicker .drp-calendar,
  .daterangepicker .ranges {
    float: left;
  }
}
@media (min-width: 730px) {
  .daterangepicker .ranges {
    width: auto;
  }
  .daterangepicker .ranges {
    float: left;
  }
  .daterangepicker.rtl .ranges {
    float: right;
  }
  .daterangepicker .drp-calendar.left {
    clear: none !important;
  }
}
.card-header-input {
  background-color: white;
  padding: 4px 0px;
  margin-right: 5px;
  border: 1px #f5d4b9 solid;
  border-radius: 5px;
  min-width: 208px;
  height: 42px;
}
input:focus,
select:focus,
form-control:focus,
.btn:focus {
  outline: none !important;
  border: 1px solid #ffffff;
  box-shadow: 0 0 5px #ed8732;
}
.table thead {
  background-color: #f6ece4;
}
.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 1px;
}
.btn-sm {
  font-size: 12px;
}
.h5,
h5 {
  font-size: 17px;
  color: #23042b;
}
.form-control {
  background-color: #f8f8f8;
  border-color: rgb(234, 234, 234);
  font-size: 13px;
}
.card {
  border: 1px solid rgb(249, 239, 231);
  box-shadow: 4px 4px 10px #ebebeb;
}
.cursorpointer {
  cursor: pointer;
}
table.dataTable {
  clear: both;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  max-width: none !important;
  border-collapse: separate !important;
  border-spacing: 0;
}
table.dataTable td,
table.dataTable th {
  box-sizing: content-box;
}
table.dataTable td.dataTables_empty,
table.dataTable th.dataTables_empty {
  text-align: center;
}
table.dataTable.nowrap th,
table.dataTable.nowrap td {
  white-space: nowrap;
}
div.dataTables_wrapper div.dataTables_length label {
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
}
div.dataTables_wrapper div.dataTables_length select {
  width: auto;
  display: inline-block;
}
div.dataTables_wrapper div.dataTables_filter {
  text-align: right;
}
div.dataTables_wrapper div.dataTables_filter label {
  font-weight: normal;
  white-space: nowrap;
  text-align: left;
}
div.dataTables_wrapper div.dataTables_filter input {
  margin-left: 0.5em;
  display: inline-block;
  width: auto;
}
div.dataTables_wrapper div.dataTables_info {
  padding-top: 0.85em;
  white-space: nowrap;
}
div.dataTables_wrapper div.dataTables_paginate {
  margin: 0;
  white-space: nowrap;
  text-align: right;
}
div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  margin: 2px 0;
  white-space: nowrap;
  justify-content: flex-end;
}
div.dataTables_wrapper div.dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  margin-left: -100px;
  margin-top: -26px;
  text-align: center;
  padding: 1em 0;
}
table.dataTable thead > tr > th.sorting_asc,
table.dataTable thead > tr > th.sorting_desc,
table.dataTable thead > tr > th.sorting,
table.dataTable thead > tr > td.sorting_asc,
table.dataTable thead > tr > td.sorting_desc,
table.dataTable thead > tr > td.sorting {
  padding-right: 30px;
}
table.dataTable thead > tr > th:active,
table.dataTable thead > tr > td:active {
  outline: none;
}
table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  cursor: pointer;
  position: relative;
}
table.dataTable thead .sorting:before,
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
  position: absolute;
  bottom: 0.9em;
  display: block;
  opacity: 0.3;
}
table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:before {
  right: 1em;
  content: "\2191";
}
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  right: 0.5em;
  content: "\2193";
}
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:after {
  opacity: 1;
}
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
  opacity: 0;
}
div.dataTables_scrollHead table.dataTable {
  margin-bottom: 0 !important;
}
div.dataTables_scrollBody table {
  border-top: none;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
div.dataTables_scrollBody table thead .sorting:before,
div.dataTables_scrollBody table thead .sorting_asc:before,
div.dataTables_scrollBody table thead .sorting_desc:before,
div.dataTables_scrollBody table thead .sorting:after,
div.dataTables_scrollBody table thead .sorting_asc:after,
div.dataTables_scrollBody table thead .sorting_desc:after {
  display: none;
}
div.dataTables_scrollBody table tbody tr:first-child th,
div.dataTables_scrollBody table tbody tr:first-child td {
  border-top: none;
}
div.dataTables_scrollFoot > .dataTables_scrollFootInner {
  box-sizing: content-box;
}
div.dataTables_scrollFoot > .dataTables_scrollFootInner > table {
  margin-top: 0 !important;
  border-top: none;
}
@media screen and (max-width: 767px) {
  div.dataTables_wrapper div.dataTables_length,
  div.dataTables_wrapper div.dataTables_filter,
  div.dataTables_wrapper div.dataTables_info,
  div.dataTables_wrapper div.dataTables_paginate {
    text-align: center;
  }
  div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    justify-content: center !important;
  }
}
table.dataTable.table-sm > thead > tr > th :not(.sorting_disabled) {
  padding-right: 20px;
}
table.dataTable.table-sm .sorting:before,
table.dataTable.table-sm .sorting_asc:before,
table.dataTable.table-sm .sorting_desc:before {
  top: 5px;
  right: 0.85em;
}
table.dataTable.table-sm .sorting:after,
table.dataTable.table-sm .sorting_asc:after,
table.dataTable.table-sm .sorting_desc:after {
  top: 5px;
}
table.table-bordered.dataTable {
  border-right-width: 0;
}
table.table-bordered.dataTable th,
table.table-bordered.dataTable td {
  border-left-width: 0;
}
table.table-bordered.dataTable th:last-child,
table.table-bordered.dataTable th:last-child,
table.table-bordered.dataTable td:last-child,
table.table-bordered.dataTable td:last-child {
  border-right-width: 1px;
}
table.table-bordered.dataTable tbody th,
table.table-bordered.dataTable tbody td {
  border-bottom-width: 0;
}
div.dataTables_scrollHead table.table-bordered {
  border-bottom-width: 0;
}
div.table-responsive > div.dataTables_wrapper > div.row {
  margin: 0;
}
div.table-responsive
  > div.dataTables_wrapper
  > div.row
  > div[class^="col-"]:first-child {
  padding-left: 0;
}
div.table-responsive
  > div.dataTables_wrapper
  > div.row
  > div[class^="col-"]:last-child {
  padding-right: 0;
}
.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(246, 236, 228, 0.5);
}
.table-bordered td,
.table-bordered th {
  border: 1px solid #f6ece4;
}
.card .card {
  box-shadow: none;
}
.table-bordered {
  border-color: #f6ece4;
}
.page-item.active .page-link {
  background-color: #23042b;
  border-color: #23042b;
}
.page-link {
  color: #ed8732;
}
.page-link:hover {
  color: #23042b;
}
.nav-tabs .nav-link {
  border-width: 0px;
  border-bottom-width: 3px;
  color: #333;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border: 0px;
  border-bottom: 3px #ed8732 solid;
  color: #ed8732;
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border: 0px;
  border-bottom: 3px #ed8732 solid;
}
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 18px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  left: 4px;
  bottom: 4px;
  background-color: #fff;
  transition: 0.4s;
}
input:checked + .slider {
  background-color: #ed8732;
}
input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}
input:checked + .slider:before {
  transform: translateX(22px);
}
.slider.round {
  border-radius: 18px;
}
.slider.round:before {
  border-radius: 50%;
}
.bootstrap-select .dropdown-toggle .filter-option-inner-inner {
  font-size: 13px;
}
.offerings-img {
  max-width: 30px;
}
.profile-pic {
  max-width: 200px;
  border: 1px #ed8732 solid;
}
.analytics-top-filter {
  background-color: #e9dcdc;
}
.analytics-top-filter select {
  width: 13%;
  margin-right: 1%;
  min-width: 100px;
}
.analytics-box {
  background-color: #4a4361;
  width: 220px;
  padding: 10px;
  color: white;
  display: inline-block;
  margin-right: 10px;
}
/*.analytics-box:nth-child(2){background-color: #4A4361;}*/
.analytics-box .text-danger {
  color: #ff5a5a !important;
}
.analytics-box .text-success {
  color: #59e278 !important;
}
h2.title {
  color: #ed8732;
  font-size: 17px;
}
.adv {
  color: white;
  padding-top: 10px;
}
.adv h4 {
  font-size: 15px;
  text-transform: uppercase;
}
.adv h2 {
  font-weight: 400;
}
.img-area-box {
  max-width: 100%;
}
.img-area-box img {
  max-height: 100%;
  max-width: 100%;
}

@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 95%;
  }
}

@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 95%;
  }
}

@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 95%;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 95%;
  }
}

.custom-white-bg {
  background-color: #fff;
}

.margin-cost-btn {
  display: flex;
  align-items: flex-end;
}

div.dataTables_filter input {
  height: calc(1.5em + 0.5rem + 2px);
  background-color: #f8f8f8;
  border-color: rgb(234, 234, 234);
  font-size: 13px;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  color: #495057;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

div.dataTables_length select {
  background-color: #f8f8f8;
  border-color: rgb(234, 234, 234);
  font-size: 13px;
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  /* background: #fff url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e) no-repeat right .75rem center/8px 10px; */
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  /* appearance: none; */
}

.dataTables_wrapper th,
.dataTables_wrapper td {
  font-size: 13px;
  color: #212529;
}

table.dataTable thead th,
table.dataTable thead td {
  border: none;
}

table.dataTable.no-footer {
  border-bottom: 1px solid #f6ece4;
}

#all-dealers_wrapper table.dataTable tbody td a {
  margin-top: 0 !important;
  margin-right: 2px;
}

table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc {
  background-image: none !important;
}

/* material UI styles for Autocomplete */
.MuiFormLabel-root.Mui-focused,
.MuiFormLabel-root.MuiFormLabel-filled {
  display: none;
}

.MuiInputBase-input {
  box-shadow: 0 0 0 !important;
}

.MuiInput-underline:after .MuiInput-underline:before {
  display: none;
  content: none !important;
}

.MuiInputLabel-formControl {
  bottom: 0 !important;
  position: absolute !important;
  transform: none !important;
  top: unset !important;
  padding-left: 5px !important;
}

.MuiInput-formControl {
  margin-top: 0 !important;
}

.videoInsert {
  /*position: absolute;*/
  /*right: 0;*/
  /*bottom: 0;*/
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  background-size: cover;
  overflow: hidden;
}

.vdp-search-table {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.my-modal .modal-content {
    background-color: rgba(0, 0, 0, 0);
    border: none;
  }
