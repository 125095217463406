body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div.suspense-fallback-loader {
  position: fixed;
  top: 50%;
  right: 50%;
  z-index: 10000000;
}

.design-card.card-stats .icon-big {
  font-size: 3em;
  min-height: 64px; }
  .design-card.card-stats .icon-big i {
    font-weight: bold;
    line-height: 59px; }

.design-card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
}

/* On mouse-over, add a deeper shadow */
.design-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

/* react-widgets MultiSelect */

.rw-list-empty, .rw-list-option, .rw-list-optgroup {
    padding: 0 .75em;
    outline: 0;
    margin-top: 0;
}

.rw-multiselect-tag {
    vertical-align: top;
    margin-top: 0 !important;
    margin-bottom: 4px;
}


/* khan/react-multi-select custom styles */

div.multi-select div.dropdown-heading {
  width: 100% !important;
  height: 29px !important;
  border: 1px #f5d4b9 solid !important;
}

div.multi-select span.dropdown-heading-dropdown-arrow span {
  border-color: rgb(153, 153, 153) transparent transparent !important;
}

div.multi-select label.select-item {
  /*width: 200px;*/
}

div.multi-select div.dropdown-content {
  width: 150% !important;
}

/* kendo react multiselect styles */

.k-widget .k-multiselect .k-header, .k-multiselect-wrap .k-floatwrap, .k-searchbar {
  border: 1px #f5d4b9 solid !important;
}

.k-multiselect .k-multiselect-wrap .k-searchbar, .k-dropdowntree .k-multiselect-wrap .k-searchbar {
  width: 100% !important;
}

.k-multiselect .k-multiselect-wrap, .k-dropdowntree .k-multiselect-wrap {
  padding: 0 !important;
}

.k-animation-container.k-animation-container-relative.k-list-container.k-reset.k-animation-container-shown {
  /*width: 25% !important;*/
  z-index: 999999 !important;
}


/* designer styles for switch (checkbox) */

.slider:before {
  bottom: 3px !important;
}

/* react kendo multiselect styles */

.k-animation-container {
  width: 300px !important;
}

.k-multiselect-wrap.k-floatwrap .k-button {
  width: 97% !important;
}

.k-animation-container .heading-filter-row {
  /*margin-left: 0.1rem !important;*/
}

.k-list-scroller .k-item {
  height: 60px !important;
}


#tableData {
  max-height: 90vh;
  overflow-y: auto;
}


/* place text at bottom right */

.main_div {
  position: relative;
  height: 200px;
}
.sub_div {
  position: absolute;
  bottom: 0px;
  right: 0px;
}


/* autocomplete dropdown input */

.autocomplete-dropdown-input {
  background-color: white;
  padding: 0px 0px;
  margin-right: 5px;
  border: 1px #f5d4b9 solid;
  border-radius: 5px;
  min-width: 150px;
  max-height: 33px;
}

.class-header-input {
  border: none;
}


/* react kendo search option focus */

.k-list .k-item:focus, .k-list .k-item.k-state-focused, .k-list-optionlabel:focus, .k-list-optionlabel.k-state-focused {
    box-shadow: inset 0 0 0 2px rgba(0,0,0,.13);
    background-color: #cccc;
}


.FiltersTable th,td{
  border: 2px #f5d4b9 solid;
}

.FiltersTable th{
  color: #212529;
  padding: 10px 0px;
  font-size: 11px;
  text-align: center;
}

.FiltersTable span{
  /* border: 2px #f5d4b9 solid; */
  padding: 1px 6px;
  margin: 2px 2px;
  display: inline-block;
  word-break: break-word;
  font-size: 13px;

}

.FiltersTable th,td{
  word-break: initial;
}


.FiltersTable td{
  max-width: 115px;
  /* min-width: 110px */
}

.FiltersTable tbody>tr>:nth-child(8),tbody>tr>:nth-child(9){
  max-width: 69px;
}

.FiltersTable tbody>tr>:nth-child(4){
  width: 20px;
}

.FiltersTable tbody>tr>:nth-child(1){
  min-width: 150px;
}

.analytics-justified-filters > .col-3{max-width: 24%!important;}


/* analytics filter justified spacing */
@media (min-width: 768px) {
  .analytics-justified-filters .col-md-2 {
      flex: 0 0 19.999999% !important;
      max-width: 20.666667% !important;
  }

  .analytics-extended-filters .col-md-2 {
    flex: 0 0 17.999999% !important;
    max-width: 20.666667% !important;
  }
}

.coverSpinner {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.coverSpinner::after {
  content: "";
  display: block;
  position: absolute;
  left: 48%;
  top: 40%;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-color: black;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: spin 0.8s linear infinite;
  animation: spin 0.8s linear infinite;
}
